import Portada from '../sections/mantenimiento/portada-mantenimiento';
import Elige from '../sections/mantenimiento/elige-mantenimiento';
import Repuestos from '../sections/mantenimiento/repuestos-mantenimiento';
import Genuinos from '../sections/mantenimiento/genuinos-mantenimiento';
import Tranquilidad from '../sections/mantenimiento/tranquilidad-mantenimiento';
import Recupera from '../sections/mantenimiento/recupera-mantenimiento';
import Garantia from '../sections/mantenimiento/garantia-mantenimiento';


const Mantenimiento = () => {

    return (
        <>
            
            <Portada />
            <Elige />
            <Repuestos />
            <Genuinos />
            <Tranquilidad />
            <Recupera />
            <Garantia />

        </>

    )
}

export default Mantenimiento;