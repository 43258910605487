import useFetch from '../../hooks/useFetch';
import Footerli from './footerli';
import logo_toyota_go from '../../images/footer/icon-toyota-go.png'
import logo_google_play from '../../images/footer/icon-google-play.png'
import logo_app_store from '../../images/footer/icon-app-store.png'

import fb from '../../images/footer/fb.png'
import ig from '../../images/footer/ig.png'
import x from '../../images/footer/x.png'
import yt from '../../images/footer/yt.png'

const Footer = ({ toyotasiempre, logotoyota }) => {
    let llenarlista = 'global/menu-secundario/'
    const [listafooter, error2] = useFetch(llenarlista);

    let url_api = 'global/toyota-info'
    const [respuesta, error] = useFetch(url_api)

    let meta_title = ''
    let url_toyota_go = ''
    let url_google_play = ''
    let url_app_store = ''
    let enlace_social_media = ''
    let urls = ''
    let url_fb = ''
    
    if (respuesta) {
        respuesta.map(c => {
           meta_title = c.meta_title
           url_toyota_go = c.url_toyota_go
           url_google_play = c.url_google_play
           url_app_store = c.url_app_store
           enlace_social_media = c.enlace_social_media
        }
        )
    }

    return (
        <footer>
            <div className="container-fluid">
                <div className="row footersup destopk">
                    <div className="col-4 col-md-4 text-center">
                        <a href={process.env.REACT_APP_URL_DOMAIN} title={meta_title}>
                            <img src={logotoyota} className="img-fluid" alt={meta_title} />
                        </a>
                    </div>
                    <div id="container-grid-footer" className="col-8 col-md-4 text-center">
                        <p className="textFooterCenter">Tu eliges la entrada y el plazo que quieres pagar para 
                            conseguir la cuota mensual que más se adapte a tus necesidades</p>
                    </div>
                    <div className="col-12 col-md-4 text-center">
                        <a href={process.env.REACT_APP_URL_DOMAIN + 'siemprenuevo'} title="Toyota Siempre Nuevo" target="_blank">
                            <img src={toyotasiempre} className="img-fluid" alt="Toyota Siempre Nuevo" />
                        </a>
                    </div>
                </div>
                <div className="row footerdown" >

                    {
                        listafooter ? (
                            <>
                                {

                                    listafooter.map((c, i) => {
                                        return < Footerli title={c.title.map(h => h.value)} field_url_menu_secundario={c.field_url_menu_secundario} i={i}></Footerli>
                                    }
                                    )
                                }
                            </>
                        ) : (
                            <span> Cargando...</span>
                        )

                    }
                    <hr className='hrfooter'></hr>
                    <div className="col-12 col-md-3"></div>
                    <div className="col-4 col-md-2 text-center">
                        <a href={url_toyota_go} title="Toyota Go" target="_blank">
                            <img src={logo_toyota_go} className="" alt="Toyota Go" />
                        </a>
                    </div>
                    <div className="col-4 col-md-2 text-center align-content-icons">
                        <a href={url_google_play} title="Disponible en Google Play" target="_blank">
                            <img src={logo_google_play} className="img-fluid" alt="Disponible en Google Play" />
                        </a>
                    </div>
                    <div className="col-4 col-md-2 text-center align-content-icons">
                        <a href={url_app_store} title="Consíguelo en el App Store" target="_blank">
                            <img src={logo_app_store} className="img-fluid" alt="Consíguelo en el App Store" />
                        </a>
                    </div>
                    <div className="col-12 col-md-3"></div>
                    <div className="col-12 text-center mt-3 mb-3">
                        
                    {
                        respuesta ? (
                            <section>
                                {

                                    respuesta.map((c, i) => {
                                        let urls = c.enlace_social_media.split(',');
                                        return (
                                            <>
                                                {i === 0 &&
                                                    <a href={urls[0]} title='Toyota Ecuador - Facebook' target='_blank'>
                                                        <img src={fb} alt="Toyota Ecuador - Facebook" width="70" height="auto" />
                                                    </a>
                                                }
                                                {i === 0 &&
                                                    <a href={urls[1]} title='Toyota Ecuador - Instagram' target='_blank'>
                                                        <img src={ig} alt="Toyota Ecuador - Instagram" width="70" height="auto" />
                                                    </a>
                                                }
                                                {i === 0 &&
                                                    <a href={urls[2]} title='Toyota Ecuador - X' target='_blank'>
                                                        <img src={x} alt="Toyota Ecuador - X" width="70" height="auto" />
                                                    </a>
                                                }
                                                {i === 0 &&
                                                    <a href={urls[3]} title='Toyota Ecuador - Youtube' target='_blank'>
                                                        <img src={yt} alt="Toyota Ecuador - Youtube" width="70" height="auto" />
                                                    </a>
                                                }
                                            </>
                                        )
                                    })
                                }
                            </section>
                        ) : (
                            <span> Cargando...</span>
                        )
                    }
                    </div>
                </div>


            </div>
        </footer>
    );
}

export default Footer;