import InfoLandCruiserPradoVersiones from '../sections/land-cruiser-prado-versiones/info';

const LandCruiserPradoVersiones = () => {


    return (
        <>
            <InfoLandCruiserPradoVersiones></InfoLandCruiserPradoVersiones>
        </>

    )
}

export default LandCruiserPradoVersiones;