import useFetch from '../../hooks/useFetch';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../images/logo.png'
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
function Header() {
	let llenar_header = 'v1/global/menu-principal'
	let url_nav = ''
	let target = ''
	let title = 'Toyota Ecuador';
	const [menu] = useFetch(llenar_header);
	
	return (
		<>
			<header>
				<Navbar collapseOnSelect expand="lg">
					<Container fluid>
						<a 
							className="d-block" 
							title={title} 
							href="https://www.toyota.com.ec/">
							<img alt={title} src={logo} />
						</a>
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav">
								<Nav className="me-auto">
									{
										menu ? (
											<>
												{
													menu.map((c, i) => {
														url_nav = c.url_menu_principal.replace("admin/", "")
														if (i === 1 || i === 3) {
																	
															target = "_blank"
														}
														return (
															<>
																{i === 3 || i === 4 || i === 5 ?
																	<Dropdown>
																		<Dropdown.Toggle variant="" id="dropdown-basic">
																			{c.nombre_menu_superior}
																		</Dropdown.Toggle>
																		{i === 3 &&
																			<Dropdown.Menu>
																				<Dropdown.Item href="https://toyotago.com.ec/" target="_blank">Toyota GO</Dropdown.Item>
																				<Dropdown.Item href="/toyota-gazoo-racing">Toyota Gazoo Racing</Dropdown.Item>
																			</Dropdown.Menu>
																		}
																		{i === 4 &&
																			<Dropdown.Menu>
																				<Dropdown.Item href="/concesionarios">Concesionarios</Dropdown.Item>
																				<Dropdown.Item href="/siemprenuevo">Toyota siempre nuevo</Dropdown.Item>
																				<Dropdown.Item href="/sostenibilidad">Sostenibilidad</Dropdown.Item>
																				<Dropdown.Item href="/dream-car">Dream Car</Dropdown.Item>
																				<Dropdown.Item href="/blog">Blog</Dropdown.Item>
																			</Dropdown.Menu>
																		}
																		{i === 5 &&
																			<Dropdown.Menu>
																				<Dropdown.Item href="/post-venta">Postventa</Dropdown.Item>
																				<Dropdown.Item href="/mantenimiento">Mantenimiento</Dropdown.Item>
																			</Dropdown.Menu>
																			
																		}
																	</Dropdown>
																	:
																	<Nav.Link 
																		key={i} 
																		href={url_nav} 
																		className={""} 
																		target={target} 
																		>
																			{c.nombre_menu_superior}
																	</Nav.Link>
																}
															</>
														)
													})
												}
											</>
										) : (
											null
										)
									}
								</Nav>
							</Navbar.Collapse>
					</Container>
				</Navbar>
			</header>
		</>
	);
}

export default Header;