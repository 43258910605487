function Navlinkfooter({ title, url }) {
    return (
        <>
        {   title == "Resoluciones y avisos legales" || 
            title == "Política de Cookies" || 
            title == "Política de Protección de Datos Personales" || 
            title == "Política de Protección de Datos Personales para Procesos de Selección" || 
            title == "Política Dream Car" || 
            title == "Blog" || 
            title == "Baja de Datos Personales" ? (

            <li><a target="_blank" href={url.replace("https://wwww.toyota.com.ec/","")} >{title}</a></li>
        ):(
            <li><a className="btn-custom-internal" href={url.replace("https://wwww.toyota.com.ec/","")} >{title}</a></li>
        )    

        }
         </>
    );
}

export default Navlinkfooter;

