

export default function Banner() {
    return (

        <div className="container-fluid contenedor " style={{ backgroundImage: `url(${'https://www.toyota.com.ec/'})` }} >

            <div className='row'>
                <div className='col-12 centrar'>
                    <p>TOYOTA</p>
                    <h1>ALARGA LA VIDA DE TU VEHÍCULO</h1>
                </div>
                <div className='col-4'>
                    <h1>ALARGA LA VIDA DE TU VEHÍCULO</h1>

                </div>
                <div className='col-4'>
                    <h1>ALARGA LA VIDA DE TU VEHÍCULO</h1>

                </div>
                <div className='col-4'>
                    <h1>ALARGA LA VIDA DE TU VEHÍCULO</h1>

                </div>

            </div>
        </div>

    );
};