import { useEffect, useState } from 'react';
import useFetch from "../../hooks/useFetch";
import BannerConcesionario from '../sections/concesionarios/banner';

const concesionarios = () => {


    return (
        <>
            <BannerConcesionario></BannerConcesionario>

        </>

    )
}



export default concesionarios;