

const Submenu = () => (

    <div className="auto-individual container-fluid" >
        <div className="row" id="menu-producto" >
            <div className="col-12 submenu"  >
                <ul className="psubmenu">
                    <li className="prmenu" >  <a href="#content-feature">MANTENIMIENTOS</a> </li>
                    <li className="prmenu" ><a href="#content-diseno">REPUESTOS Y LUBRICANTES</a></li>
                    <li className="prmenu" > <a href="#tecnologia">GARANTÍA EXTENDIDA TOYOTA</a> </li>

                </ul>
                <a href="#tecnologia" className=" btnsubmenu psubmenu">CONTACTAR</a>
            </div>
        </div>
    </div>


)

export default Submenu