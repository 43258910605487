import Submenu from "../sections/submenu";
import Banner from "../sections/postventa/banner";
import Filtros from "../sections/postventa/filtros";
import Cartcta from "../sections/cart/cart-cta";
import Tecno from "../sections/cart/cart-tecnologia"
const Posventa = () => {


    return (
        <>
            <Submenu></Submenu>
            <Banner></Banner>
            <Filtros></Filtros>
            <Tecno></Tecno>
            <Cartcta></Cartcta>

        </>

    )
}

export default Posventa;