import { useEffect, useState } from 'react';
import useFetch from "../../hooks/useFetch";
import Banner from '../sections/siempre-nuevo/banner';
import Body from '../sections/siempre-nuevo/body';
import Comofunciona from '../sections/siempre-nuevo/comofunciona';
import Estrenalo from '../sections/siempre-nuevo/estrenalo';
import Video from '../sections/siempre-nuevo/video';
import Form from '../sections/siempre-nuevo/form';
import Upgrade from '../sections/siempre-nuevo/upgrade';

const SiempreNuevo = () => {


    return (
        <>
            <Body></Body>
        </>

    )
}



export default SiempreNuevo;