import useFetch from '../../../hooks/useFetch';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useRef, useState } from "react";

import ImgLandCruiserPrado from '../../../images/land-cruiser-prado/land-cruiser-prado.png';
import LogoLandCruiserPrado from '../../../images/land-cruiser-prado/logo-land-cruiser-prado.png';

import ImgLandCruiserPradoSombra from '../../../images/land-cruiser-prado/land-cruiser-prado-250.png';
import LogoLandCruiserPradoSombra from '../../../images/land-cruiser-prado/logo-lc-250.png';
import VideoLandCruiserPrado from '../../../videos/land-cruiser-prado-versiones.mp4';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

export default function InfoLandCruiserPradoVersiones() {

    let video = VideoLandCruiserPrado;

    const videoRef = useRef();


    useEffect(() => {    
        videoRef.current?.load();
    }, [video]);

    return (
        <>
            <div id="auto-individual-banner" className="auto-individual container-fluid video-cart d-none d-sm-none d-md-block d-xl-block d-lg-block" >

                <div className="row">
                    <div className="col-12 col-sm-12 content-video">
                        <video width="100%" autoPlay muted loop controls="false" ref={videoRef} >
                            <source src={video} type="video/mp4"  />
                            Your browser does not support HTML video.
                        </video>
                    </div>
                </div>
            </div>

            <div id="content-feature-rav4" class="container-fluid contenedor d-md-block d-xl-block d-lg-block">
                <div className='mtopbanner-rav4'>
                    <div className="row">
                        <div className="col-12 col-sm-12 text-center"><h2 className='title-slider-blue'>CONTINUA EL LEGADO</h2></div>
                        <div className="col-12 col-sm-6 text-center">
                            <img src={ImgLandCruiserPrado} className="img-fluid" />
                            <img src={LogoLandCruiserPrado} className="img-fluid mt-4" />
                            <h3 className='title-box-rav4'>
                                <a href={process.env.REACT_APP_URL_DOMAIN + 'land-cruiser-prado'}>MOTOR V6 4.0 L</a>
                            </h3>
                        </div>
                        <div className="col-12 col-sm-6 text-center">
                            <img src={ImgLandCruiserPradoSombra} className="img-fluid" />
                            <img src={LogoLandCruiserPradoSombra} className="img-fluid mt-4" />
                            <h3 className='title-box-rav4'>
                            <a href={process.env.REACT_APP_URL_DOMAIN + 'land-cruiser-prado-250'}>Motor 2.4L TURBO</a>
                            </h3>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </>
    );
}