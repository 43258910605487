import useFetch from '../../../hooks/useFetch';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useRef, useState } from "react";

import ImgRav4Combustible from '../../../images/rav4/rav4-combustion.png';
import LogoRav4Combustible from '../../../images/rav4/logo-rav4-2.0.png';

import ImgRav4Hibrido from '../../../images/rav4/rav4-hibrido.png';
import LogoRav4Hibrido from '../../../images/rav4/logo-rav4-hybrid.png';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

export default function InfoRav4LandingPage() {

    let url_api = 'global/dreamcar'
    const [respuesta, error] = useFetch(url_api)

    let dream_car_image = ''
    let dream_car_title = ''
    let dream_car_body = ''
    let dream_car_form = ''
    let dream_car_base = ''
    let dream_car_bullet_list = ''
    let dream_car_body_2 = ''
    let dream_car_body_3 = ''
    let dream_car_winner_1 = ''
    let dream_car_winner_2 = ''
    let dream_car_winner_3 = ''
    let dream_car_item_1 = ''
    let dream_car_item_2 = ''
    let dream_car_item_3 = ''
    let dream_car_item_4 = ''
    let dream_car_btn_1 = ''
    let dream_car_btn_2 = ''

    
    if (respuesta) {
        respuesta.map(c => {
                dream_car_title         = c.dream_car_title
                dream_car_image         = c.dream_car_image
                dream_car_body          = c.dream_car_body
                dream_car_form          = c.dream_car_form
                dream_car_base          = c.dream_car_base
                dream_car_bullet_list   = c.dream_car_bullet_list
                dream_car_body_2        = c.dream_car_body_2
                dream_car_body_3        = c.dream_car_body_3
                dream_car_winner_1      = c.dream_car_winner_1
                dream_car_winner_2      = c.dream_car_winner_2
                dream_car_winner_3      = c.dream_car_winner_3
                dream_car_item_1        = c.dream_car_item_1
                dream_car_item_2        = c.dream_car_item_2
                dream_car_item_3        = c.dream_car_item_3
                dream_car_item_4        = c.dream_car_item_4
                dream_car_btn_1         = c.dream_car_btn_1
                dream_car_btn_2         = c.dream_car_btn_2
            }
        )
    }

    let video = 'https://www.toyota.com.ec//admin/sites/default/files/2022-07/portada-rav4-1920X650.mp4'

    const videoRef = useRef();


    useEffect(() => {    
        videoRef.current?.load();
    }, [video]);

    return (
        <>
            <div id="auto-individual-banner" className="auto-individual container-fluid video-cart d-none d-sm-none d-md-block d-xl-block d-lg-block" >

                <div className="row">
                    <div className="col-12 col-sm-12 content-video">
                        <video width="100%" autoPlay muted loop controls="false" ref={videoRef} >
                            <source src={video} type="video/mp4"  />
                            Your browser does not support HTML video.
                        </video>
                    </div>
                </div>
            </div>

            <div id="content-feature-rav4" class="container-fluid contenedor d-md-block d-xl-block d-lg-block">
                <div className='mtopbanner-rav4'>
                    <div className="row">
                        <div className="col-12 col-sm-12 text-center"><h2 className='title-slider-blue'>NUEVAS HISTORIAS CON RAV4</h2></div>
                        <div className="col-12 col-sm-6 text-center">
                            <img src={ImgRav4Combustible} className="img-fluid" />
                            <img src={LogoRav4Combustible} className="img-fluid" />
                            <h3 className='title-box-rav4'>
                                <a href={process.env.REACT_APP_URL_DOMAIN + 'rav4'}>MOTOR 2.0L/ COMBUSTIÓN</a>
                            </h3>
                        </div>
                        <div className="col-12 col-sm-6 text-center">
                            <img src={ImgRav4Hibrido} className="img-fluid" />
                            <img src={LogoRav4Hibrido} className="img-fluid" />
                            <h3 className='title-box-rav4'>
                                <a href={process.env.REACT_APP_URL_DOMAIN + 'rav4-hibrido'}>MOTOR 2.5L / HÍBRIDO</a>
                            </h3>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </>
    );
}