import InfoRav4LandingPage from '../sections/rav4-landing-page/info';

const Rav4LandingPage = () => {


    return (
        <>
            <InfoRav4LandingPage></InfoRav4LandingPage>
        </>

    )
}

export default Rav4LandingPage;