import InfoDreamCar from '../sections/dream-car/info';

const DreamCar = () => {


    return (
        <>
            <InfoDreamCar></InfoDreamCar>
        </>

    )
}

export default DreamCar;