import React, { useEffect, useRef, useState } from "react";

import { get } from 'axios';

import { motion } from 'framer-motion'


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import useFetch from "../../../hooks/useFetch2";
import link_externo from '../../../images/cotizacion/link-externo.svg'
import cerrar from '../../../images/cotizacion/cerrar.svg'

export default function ExoneradosForm( { carro } ) {

    const [proceso, setProceso] = useState('');

    function handleClick() {

        let discapacidad = document.getElementById("discapacidad").value;
        let modelo = document.getElementById("modelo").value;
        let nombre = document.getElementById("nombre").value;
        let apellido = document.getElementById("apellido").value;
        let celular = document.getElementById("celular").value;
        let telefono = document.getElementById("telefono").value;
        let concesionario = document.getElementById("concesionario").value;
        let email = document.getElementById("email").value;
        let cedula = document.getElementById("cedula").value;
        let direccion = document.getElementById("direccion").value;
        let tipo_exoneracion = document.getElementById("tipo-exoneracion").value;
        let toyota = "SN";
        let terminos = document.getElementById('terminos').checked;

        if (modelo == '' || nombre == '' || apellido == '' || celular == '' || telefono == '' || concesionario == '' || email == '' || concesionario == '' 
        || email == '' || cedula == '' || direccion == '' || tipo_exoneracion == '' || toyota == '' || terminos != true) {
            alert('Todos los campos son obligatorios.')
        } else {

            const getproceso = async () => {
                const resultexo = await get(`${'https://www.toyota.com.ec/api/v2/exonerados/?nombre=' + nombre 
                                                                                       + '&apellido=' + apellido 
                                                                                         + '&cedula=' + cedula 
                                                                                        + '&celular=' + celular 
                                                                                          + '&email=' + email 
                                                                                       + '&telefono=' + telefono 
                                                                                      + '&direccion=' + direccion
                                                                                         + '&toyota=' + toyota
                                                                                         + '&modelo=' + modelo 
                                                                                  + '&concesionario=' + concesionario
                                                                               + '&tipo_exoneracion=' + tipo_exoneracion
                                                                                   + '&discapacidad=' + discapacidad
                                                                                    }`);
                const procesos = resultexo.data;
                setProceso(procesos);

            }

            var elemento = document.getElementById("enviar-cotizar");
            elemento.classList.add("ocultar-icono");

            var loaderForm = document.getElementById("loader-form");
            loaderForm.classList.add("show");

            getproceso()

        }

    }

    function btnTerminos() {
        var terminos = document.getElementById('terminos').checked;
        if (terminos == true) {
            document.getElementById('enviar-cotizar').classList.add('show');
            document.getElementById('enviar-cotizar').classList.remove('hide');
        } else {
            document.getElementById('enviar-cotizar').classList.remove('show');
            document.getElementById('enviar-cotizar').classList.add('hide');
        }
    }

    function selectOnchange() {

        let tipo_exoneracion = document.getElementById("tipo-exoneracion").value;

        if (tipo_exoneracion == "Persona con Discapacidad") {
            var val_discapacidad = document.getElementById("row_discapacidad");
            val_discapacidad.classList.remove('hide');
            val_discapacidad.classList.add("show");
        } else {
            var val_discapacidad = document.getElementById("row_discapacidad");
            val_discapacidad.classList.remove('show');
            val_discapacidad.classList.add("hide");
        }
    }

    setTimeout(
        function () {

            if (proceso.status == 200) {

                var elemento = document.getElementById("enviar-cotizar");
                var mensaje = document.getElementById("mensaje-cotizar");
                var loaderForm = document.getElementById("loader-form");

                elemento.classList.add("ocultar-icono");
                mensaje.innerHTML = proceso.msj;
                mensaje.classList.remove("ocultar-icono");
                loaderForm.classList.remove("show");
                loaderForm.classList.add("hide");


            } else if (proceso.status == 404) {

                var elemento = document.getElementById("enviar-cotizar");
                var mensaje = document.getElementById("mensaje-cotizar");

                elemento.classList.add("ocultar-icono");
                mensaje.innerHTML = proceso.msj;
                mensaje.classList.remove("ocultar-icono");

            }

        }
            .bind(this),
        800
    );

    function toTitleCase(str) {
        const titleCase = str
          .replace('/', '')
          .replace(/-/g, ' ')
          .toLowerCase()
          .split(' ')
          .map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join(' ');
      
        return titleCase;
    }

    function cerrarModal() {
        var modal = document.getElementById("myModalExonerado");
        modal.style.display = "none";
    }

    return (
        <>

            <div id="myModalExonerado" className="modal">

                <div className="modal-content cuerpo-modal">

                    <div className='container-fluid' >
                        <div className="content-select-exo">
                            <div className="row" >
                                <div className="col-12" >
                                    <p className="close cerrar-modal-takata" onClick={cerrarModal} >CERRAR <img src={cerrar} /> </p>
                                </div>
                            </div>
                        </div>
                        <form method="POST">
                            <div class="row">
                                <div className="col-sm-6">
                                    <label for="tipo-exoneracion" class="form-label">TIPO DE EXONERACIÓN</label>
                                </div>
                                <div class="col-sm-6">
                                    <select id="tipo-exoneracion" name="tipo-exoneracion" onChange={selectOnchange} >
                                        <option value="" >-- Seleccione --</option>
                                        <option value="Persona con Discapacidad">Persona con Discapacidad</option>
                                        <option value="Diplomáticos">Diplomáticos</option>
                                        <option value="Organismos Internacionales">Organismos Internacionales</option>
                                        <option value="Compras Públicas">Compras Públicas</option>
                                    </select>
                                </div>
                            </div>
                            <div id="row_discapacidad" class="hide">
                                <div className="col-sm-6 colTextInline">
                                    <label for="discapacidad" class="form-label">% DE DISCAPACIDAD</label>
                                </div>
                                <div class="col-sm-6 colTextInlinePadding">
                                    <select id="discapacidad" name="discapacidad">
                                        <option value="" >-- Seleccione --</option>
                                        <option value="30 - 49%">30 - 49%</option>
                                        <option value="50 - 74%">50 - 74%</option>
                                        <option value="75 - 84%">75 - 84%</option>
                                        <option value="85 - 100%">85 - 100%</option>

                                    </select>
                                </div>
                            </div>

                            <div class="row">
                                <div className="col-sm-6">
                                    <label for="vehiculo" class="form-label">MODELO DE INTERÉS</label>
                                </div>
                                <div class="col-sm-6">
                                    {carro ?
                                        <select id="vehiculo" name="vehiculo">
                                            <option value={toTitleCase(carro)} selected="selected">{toTitleCase(carro)}</option>
                                        </select>
                                        :

                                        <select id="vehiculo" name="vehiculo">
                                            <option value="" >-- Seleccione --</option>
                                            <option value="Raize">Raize</option>
                                            <option value="Yaris Sport">Yaris Sport</option>
                                            <option value="Yaris Fastback">Yaris Fastback</option>
                                            <option value="Corolla Sedán">Corolla Sedán</option>
                                            <option value="Corolla Cross">Corolla Cross</option>
                                            <option value="C-HR Hibrido">C-HR Híbrido</option>
                                            <option value="RAV 4">RAV 4</option>
                                            <option value="Fortuner">Fortuner</option>
                                            <option value="Land Cruiser Prado">Land Cruiser Prado</option>
                                            <option value="Land Cruiser 200">Land Cruiser 300</option>
                                            <option value="Rush">Rush</option>
                                            <option value="Hilux 4X2 Cabina Doble">Hilux 4X2 Cabina Doble</option>
                                            <option value="Hilux 4x4 Cabina Doble">Hilux 4x4 Cabina Doble</option>
                                            <option value="Hilux Cabina Simple">Hilux Cabina Simple</option>
                                            <option value="Hilux 4X4 Cabina Doble AT">Hilux 4X4 Cabina Doble AT</option>
                                        </select>

                                    }
                                </div>
                            </div>

                            <div class="row">
                                <div className="col-sm-6">
                                    <label for="concesionario" class="form-label">CIUDAD Y CONCESIONARIO</label>
                                </div>
                                <div class="col-sm-6">
                                <select id="concesionario" name="concesionario">
                                    <option value="" selected="selected">-- Seleccione --</option>
                                    <option value="Quito (Casabaca)">Quito (Casabaca)</option>
                                    <option value="Santo Domingo (Casabaca)">Santo Domingo (Casabaca)</option>
                                    <option value="El Coca (Casabaca)">El Coca (Casabaca)</option>
                                    <option value="Ambato (Automotores Carlos Larrea)">Ambato (Automotores Carlos Larrea)</option>
                                    <option value="Ibarra (Comercial Hidrobo)">Ibarra (Comercial Hidrobo)</option>
                                    <option value="Riobamba (Importadora Tomebamba)">Riobamba (Importadora Tomebamba)</option>
                                    <option value="Azogues (Importadora Tomebamba)">Azogues (Importadora Tomebamba)</option>
                                    <option value="Cuenca (Importadora Tomebamba)">Cuenca (Importadora Tomebamba)</option>
                                    <option value="Loja (Importadora Tomebamba)">Loja (Importadora Tomebamba)</option>
                                    <option value="Macas (Importadora Tomebamba)">Macas (Importadora Tomebamba)</option>
                                    <option value="Machala (Importadora Tomebamba)">Machala (Importadora Tomebamba)</option>
                                    <option value="Guayaquil (Toyocosta)">Guayaquil (Toyocosta)</option>
                                    <option value="Daule (Toyocosta)">Daule (Toyocosta)</option>
                                    <option value="Quevedo (Toyocosta)">Quevedo (Toyocosta)</option>
                                    <option value="Manta (Toyocosta)">Manta (Toyocosta)</option>
                                </select>
                                </div>
                            </div>
                            <input type="text" class="form-control" id="nombre" name="nombre" placeholder="NOMBRE*"></input>
                            <input type="text" class="form-control" id="apellido" name="apellido" placeholder="APELLIDO*"></input>
                            <input type="text" class="form-control" id="cedula" name="cedula" placeholder="CÉDULA*" maxLength="13"></input>
                            <input type="tel" class="form-control" id="telefono" name="telefono" placeholder="TELÉFONO*" maxLength="10"></input>
                            <input type="tel" class="form-control" id="celular" name="celular" placeholder="CELULAR*" maxLength="10"></input>
                            <input type="text" class="form-control" id="direccion" name="direccion" placeholder="DIRECCIÓN*"></input>
                            <input type="email" class="form-control" id="email" name="email" placeholder="CORREO ELECTRÓNICO*"></input>

                            <div className="content-checkbox" >
                                <input type="checkbox" name="terminos" id="terminos" onChange={btnTerminos} />
                                <label htmlFor="terminos">
                                    <a href="/terminos-y-condiciones" target="_blank" className='link-terminos' >Términos y condiciones <img src={link_externo} /></a>   
                                </label>
                            </div>
                            <a id="enviar-cotizar" className="boton-enviar hide" onClick={handleClick}>COTIZAR</a>
                            <div id="loader-form" class="loader hide"></div>
                            <p id="mensaje-cotizar" class="ocultar-icono mensaje-formulario"></p>
                        </form>

                    </div>

                </div>
            </div>

        </>
    );
}







