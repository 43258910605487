// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";

import imagen1 from '../images/sliderqdr/toyota-gazzo-racing.GR.jpg'
import imagen2 from '../images/sliderqdr/por-que-un-toyota-QDR.jpg'
import imagen3 from '../images/sliderqdr/toyota-go-comunidad-unete.jpg'
import imagen4 from '../images/sliderqdr/movilidad-para-todos-toyota-ecuador.jpg'


import "swiper/css";
import "swiper/css/pagination";


export default function App() {
  //let llenarbanner = 'home/slider-qdr'
  //const [banner, error] = useFetch(llenarbanner);
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper SliderQDR mobile"
      >
        <SwiperSlide>
          <a href="https://www.toyota.com.ec/toyota-gazoo-racing/" title="Toyota Gazoo Racing">
            <img className="img-fluid" src={imagen1} alt="imagen q" />
          </a>
        </SwiperSlide>
        <SwiperSlide><img className="img-fluid" src={imagen2} alt="imagen q" /></SwiperSlide>
        <SwiperSlide>
          <a href="https://toyotago.com.ec/" target="_blank" title="Toyota GO">
            <img className="img-fluid" src={imagen3} alt="imagen q" />
          </a>
        </SwiperSlide>
        <SwiperSlide><img className="img-fluid" src={imagen4} alt="imagen q" /></SwiperSlide>


      </Swiper>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper SliderQDR destopk"
      >
        <SwiperSlide>
          <a href="https://www.toyota.com.ec/toyota-gazoo-racing/" title="Toyota Gazoo Racing">
            <img className="img-fluid" src={imagen1} alt="imagen q" />
          </a>
        </SwiperSlide>
        <SwiperSlide><img className="img-fluid" src={imagen2} alt="imagen q" /></SwiperSlide>
        <SwiperSlide>
          <a href="https://toyotago.com.ec/" target="_blank" title="Toyota GO">
            <img className="img-fluid" src={imagen3} alt="imagen q" />
          </a>
        </SwiperSlide>
        <SwiperSlide><img className="img-fluid" src={imagen4} alt="imagen q" /></SwiperSlide>

      </Swiper>
    </>
  );
}
