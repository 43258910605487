import React, { useEffect, useRef, useState } from "react";
import { useInView } from 'framer-motion'

import "video-react/dist/video-react.css";

import { Player } from 'video-react';

import useFetch from '../../../hooks/useFetch';


export default function Comofunciona() {

    let url_api = 'global/siempre-nuevo'
    const [respuesta, error] = useFetch(url_api)

    let tsn_banner = ''
    let tsn_body = ''
    let tsn_logo_como_funciona = ''
    let tsn_siempre_nuevo_pasos_cf_1 = ''
    let tsn_siempre_nuevo_pasos_cf_2 = ''
    let tsn_siempre_nuevo_pasos_cf_3 = ''
    let tsn_siempre_nuevo_pasos_cf_4 = ''
    let tsn_siempre_nuevo_pasos_cf_5 = ''
    let tsn_siempre_nuevo_pasos_cf_6 = ''
    let tsn_siempre_nuevo_pasos_cf_7 = ''
    let tsn_siempre_nuevo_pasos_cf_8 = ''
    let tsn_siempre_nuevo_pasos_cf_9 = ''
    let tsn_siempre_nuevo_pasos_cf_10 = ''
    let tsn_siempre_nuevo_pasos_cf_11 = ''
    let tsn_siempre_nuevo_yt = ''
    let tsn_slide = ''
    
    if (respuesta) {
        respuesta.map(c => {

                tsn_banner  = c.tsn_banner
                tsn_body    = c.tsn_body
                tsn_logo_como_funciona    = c.tsn_logo_como_funciona
                tsn_siempre_nuevo_pasos_cf_1    = c.tsn_siempre_nuevo_pasos_cf_1
                tsn_siempre_nuevo_pasos_cf_2    = c.tsn_siempre_nuevo_pasos_cf_2
                tsn_siempre_nuevo_pasos_cf_3    = c.tsn_siempre_nuevo_pasos_cf_3
                tsn_siempre_nuevo_pasos_cf_4    = c.tsn_siempre_nuevo_pasos_cf_4
                tsn_siempre_nuevo_pasos_cf_5    = c.tsn_siempre_nuevo_pasos_cf_5
                tsn_siempre_nuevo_pasos_cf_6    = c.tsn_siempre_nuevo_pasos_cf_6
                tsn_siempre_nuevo_pasos_cf_7    = c.tsn_siempre_nuevo_pasos_cf_7
                tsn_siempre_nuevo_pasos_cf_8    = c.tsn_siempre_nuevo_pasos_cf_8
                tsn_siempre_nuevo_pasos_cf_9    = c.tsn_siempre_nuevo_pasos_cf_9
                tsn_siempre_nuevo_pasos_cf_10   = c.tsn_siempre_nuevo_pasos_cf_10
                tsn_siempre_nuevo_pasos_cf_11   = c.tsn_siempre_nuevo_pasos_cf_11
                tsn_siempre_nuevo_yt            = c.tsn_siempre_nuevo_yt
                tsn_slide    = c.tsn_slide
            }
        )
    }

    return (
        <>

            <div className="container-fluid text-center container-slide-principal">
                <div className="row ">
                    <div className="col-12 col-sm-12">
                        <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_slide} className="img-fluid" />
                        <div className="title-float-slide">Toyota Siempre Nuevo</div>
                    </div>
                </div>
            </div>
            <div className="container text-center">
                <div className="row ">
                    <div className="col-12 col-sm-12">
                        <h1 className="titleFirstTopBody-1">Una inversión inteligente.</h1>
                        <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_banner} className="logo-TSN" />
                    </div>
                </div>
            </div>

            <div className="container-fluid cuadricula-bg">
                <div className="row">
                    <div className="col-12 col-sm-12">
                        <div className="container">
                            <div className="bodyFirstTopBody-1">{tsn_body}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container text-center">
                <div className="row ">
                    <div className="col-12 col-sm-12">
                        <h1 className="titleFirstTopBody-1">¿Cómo funciona?</h1>
                        <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_logo_como_funciona} className="logo-CF" />
                    </div>
                </div>
            </div>

            <div className="container text-center mb-5">
                <div className="row ">
                    <div className="col-12 col-sm-4">
                        <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_siempre_nuevo_pasos_cf_1} className="img-fluid" />
                    </div>
                    <div className="col-12 col-sm-4">
                        <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_siempre_nuevo_pasos_cf_2} className="img-fluid" />
                    </div>
                    <div className="col-12 col-sm-4">
                        <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_siempre_nuevo_pasos_cf_3} className="img-fluid" />
                    </div>
                </div>
            </div>


            <div className="container-fluid pt-5 pb-5 cuadricula-bg">
                <div className="container text-center">
                    <div className="row mx-0 justify-content-center mb-5">
                        <div className="col-12 col-sm-4">
                            <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_siempre_nuevo_pasos_cf_4} className="img-fluid" />
                        </div>
                        <div className="col-12 col-sm-4">
                            <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_siempre_nuevo_pasos_cf_5} className="img-fluid" />
                        </div>
                        <div className="col-12 col-sm-4">
                            <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_siempre_nuevo_pasos_cf_6} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container text-center">
                    <div className="row mx-0 justify-content-center mb-5">
                        <div className="col-12 col-sm-12 mb-5">
                            <h1 className="titleFirstTopBody-1">Inversión inteligente, beneficios únicos.</h1>
                        </div>
                        <div className="col-12 col-sm-4">
                            <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_siempre_nuevo_pasos_cf_7} className="img-fluid" />
                        </div>
                        <div className="col-12 col-sm-4">
                            <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_siempre_nuevo_pasos_cf_8} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container text-center">
                <div className="row mx-0 justify-content-center mb-5">
                    <div className="col-12 col-sm-4">
                        <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_siempre_nuevo_pasos_cf_9} className="img-fluid" />
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container text-center">
                    <div className="row mx-0 justify-content-center mb-5">
                        <div className="col-12 col-sm-4">
                            <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_siempre_nuevo_pasos_cf_10} className="img-fluid" />
                        </div>
                        <div className="col-12 col-sm-4">
                            <img src={process.env.REACT_APP_URL_DOMAIN_SIMPLE + tsn_siempre_nuevo_pasos_cf_11} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}